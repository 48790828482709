@tailwind base;
@tailwind components;
@tailwind utilities;

.left-image-text-section-followers {
  left: 124px;
  top: 65%;
}

.right-image-text-selection-azure {
  right: 0px;
  top: 35%;
  transform: translateY(-50%);
}

.thunder-background {
  background-color: #c7954f5c;
}

.signup-button-container {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2rem;
}

/* Add these new classes for logged-in state */
.right-image-text-selection-azure-logged-in {
  right: 0px;
  top: 35%;
  transform: translateY(-50%);
}

.signed-in-text {
  position: absolute;
  right: -220px;
  top: 65%;
  color: #6B7280;
  font-size: 0.875rem;
}